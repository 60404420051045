@font-face {
  font-family: "golos";
  src: local("golos-regular"), url("src/styles/fonts/GolosText-Regular.ttf");
}

@font-face {
  font-family: "golos";
  src: local("golos-semiBold"), url("src/styles/fonts/GolosText-SemiBold.ttf");
}

@font-face {
  font-family: "golos";
  src: local("golos-black"), url("src/styles/fonts/GolosText-Black.ttf");
}

@font-face {
  font-family: "golos";
  src: local("golos-bold"), url("src/styles/fonts/GolosText-Bold.ttf");
}

@font-face {
  font-family: "golos";
  src: local("golos-extra-bold"), url("src/styles/fonts/GolosText-ExtraBold.ttf");
}

@font-face {
  font-family: "golos";
  src: local("golos-medium"), url("src/styles/fonts/GolosText-Medium.ttf");
}

html,
body {
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
  'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  margin: 0;
}

